.plamper-stream-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
}

.volume-slider {
  width: 80px;
}