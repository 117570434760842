
.plamper-follow-list {
  text-transform: uppercase;
}

.plamper-chat-container {
  display: block;
  height: 100%;
  width: 100%;
}

.channel {
  height: 100%;
}

.plamper-follow-list { padding-top: 10px; }
.plamper-follow-list a:link { text-decoration: none; }
.plamper-follow-list a:visited { text-decoration: none; }
.plamper-follow-list a:hover { text-decoration: none; }
.plamper-follow-list a:active { text-decoration: none; }


.plamper-followed-channels {
  padding-top: 10px;
}

.plamper-follower .display-name {
  color: gray;
}

.capitalize {
  text-transform: capitalize;
}

.plamper-display-name-offline {
  color: grey;
}

.bg-primary {
  background-color: #9147ff
}