
.plamper-stream {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 432px;
  margin-right: 8px;
  background-color: rgb(24, 24, 27);
}

.plamper-stream-zoom {
  width: 720px;
}

.plamper-video-container {
  min-height: 250px;
}

.plamper-video-container-zoom {
  min-height: 420px;
}

.plamper-stream-header a:link { text-decoration: none; }
.plamper-stream-header a:visited { text-decoration: none; }
.plamper-stream-header a:hover { text-decoration: none; }
.plamper-stream-header a:active { text-decoration: none; }