.plamper-login {
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
}

.plamper-login a:link { text-decoration: none; }
.plamper-login a:visited { text-decoration: none; }
.plamper-login a:hover { text-decoration: none; }
.plamper-login a:active { text-decoration: none; }

.plamper-cookie-warn {
  color: red;
  font-weight: bold;
  font-size: 24px;
  background-color: #440000;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.plamper-login-displayname {
  font-size: 0.9rem;
}