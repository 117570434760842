.form-control {
  background-color: #111;
  border-color: #222;
  font-size: 11px;
  margin-bottom: 8px;
  color: #999
}

.form-control:focus {
  background-color: rgb(97, 49, 169);
  color: white;
}

.plamper-channel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.plamper-live-channel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.plamper-info-button {
  background-color: #9147ff;
  cursor: pointer;
}

.plamper-followed-channel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  flex-wrap: nowrap;
}

.plamper-display-name-online-icon {
  color: #01458e
}

.plamper-display-name-online {
  color: #3e9cff;
}

.plamper-display-name-offline {
  color: #666
}

.plamper-live-stream-info {
  color: #555;
}

.plamper-autojoin {
  margin-left: 5px;
  font-size: 20px;
  line-height: 1em;
  margin-right: 8px;
}

.plamper-autojoin-on {
  color: #9147ff;
}


.plamper-autojoin-switch-on {
  color: #9147ff;
}

.plamper-autojoin-switch-off {
  color: #5c2ea2;
}

.popover-header {
  background-color: rgb(97, 49, 169);
}

.popover-body {
  background-color: #222;
  color: #aaa;
}

.plamper-editcard-header {
  font-weight: bold;
}

.card-header {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.card-body {
  padding-top: 0.5rem;
  padding-bottom: 0.2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.plamper-editcard-options {
  display: flex;
  flex-direction: column;
}

.plamper-options-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.plamper-options-row .full {
  display: flex;
  flex-grow: 1;
}

.plamper-followed-channels-collapse {
  border-top: 0.2rem dotted #222;
  padding-top: 0.5rem;
}

.plamper-tags-row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.plamper-suggesions-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.plamper-suggesions-list li {
  display: inline;
}

.plamper-tag-wrapper button {
  margin-left: 5px;
}

.plamper-category-vip {
  margin-top: 10px;
  color:#c9c9c9;
}

.plamper-category-custom {
  margin-top: 10px;
  color:#666;
}

.plamper-category-twitch {
  margin-top: 10px;
  color:#666;
}
