.plamper-main-layout {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  height: 100%;
  font-size: 1.2em;
}

.plamper-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  overflow-y: hidden;
}

.plamper-left-nav {
  min-width: 260px;
  width: 260px;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: 1em;
  padding-right: 0.35em;
  margin-right: 0.25em;
}

.plamper-left-nav-toprow {
  display: flex;
  align-content: space-between;
}

.plamper-left-nav-hide {
  min-width: 3em;
  width: 3em;
}

.plamper-left-nav::-webkit-scrollbar { width: 0.4em; }
.plamper-left-nav::-webkit-scrollbar-track { background-color: #000; }
.plamper-left-nav::-webkit-scrollbar-thumb { background-color: #111; }

.plamper-left-nav:hover::-webkit-scrollbar-track { background-color: #111; }
.plamper-left-nav:hover::-webkit-scrollbar-thumb { background-color: #9147ff; }


.plamper-streams {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
}
