.plamper-debug {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.plamper-debug-msg {
  display: flex;
  justify-content: flex-end;
}

.plamper-debug {
  position: absolute;
  top: 0;
  left: 0;
  height: 2em;
  background-color: rgb(104, 0, 92);
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-left: 1em;
  padding-right: 1em;
}

.plamper-error {
  background-color: red;
}

.plamper-layout {
  /* background-color: #444; */
}

.plamper-follow-list {
  /* background-color: #003333; */
}

.plamper-login {
  /* background-color: #005511; */
}

.plamper-container {
  /* background-color: #333; */
}

.plamper-left-nav {
}

.plamper-streams {
  /* background-color: #440000; */
}